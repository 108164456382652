<template>

  <section class="shimmer-section px-0">
      <div class="row m-0 p-0">
      <div v-for="index in 10" :key="index" class="shimmer-wrapper box shine col-lg-2 col-md-2 col-sm-2 col-4 m-0 p-0">
        
      </div>
      </div>

    
  </section>

</template>
<style scoped>
.shine {
  background: #293746;
  background-image: linear-gradient(to right, #293746 0%, #23313D 20%, #293746 40%, #293746 100%);
  background-repeat: no-repeat;
  background-size: 800px 135px; 
  display: inline-block;
  position: relative; 
  
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: forwards; 
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: placeholderShimmer;
  -webkit-animation-timing-function: linear;
  }

.box {
  height: 140px;
  width: 100%;
  border-radius: 5px;
}
.header-box{
    height: 100px;
  width: 100%;
  border-radius: 5px;
}

.lines {
  height: 10px;
  width: 100%; 
}
.line-competition-left{
    width: 60%; 
}
.line-competition-right{
    width: 60%; 
    margin-right: 0px;
}
.line-team-name{
    width: 50%; 
    height: 15px;
    margin-bottom: 3px;
}

.line-team-markets{
    width: 20%; 
    height: 10px;
    margin-bottom: 3px;
}
photo {
  display: block!important;
  width: 325px; 
  height: 100px; 
  margin-top: 15px;
}

@-webkit-keyframes placeholderShimmer {
  0% {
    background-position: -468px 0;
  }
  
  100% {
    background-position: 468px 0; 
  }
}


</style>
<script>
export default {
  name: "ShimmerCasino",
}
</script>